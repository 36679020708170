.tag {
    display: inline-block;
    padding: 4px 12px;
    border: 1px solid #CDCDCD;
    border-radius: 100px;
  }

.tag-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
}
.chip-title{
  padding: 4px 0px;
  padding-right: 12px;
  white-space: nowrap;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 14px; 
  color: #222222;
}
.tag-chips-container{
  display: flex;
  font-family: 'Pretendard';
  font-weight: 300;
  font-size: 14px; 
  gap: 10px;
  padding: 0px 11px;
}
.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  background: none;
  border: none;
  cursor: pointer;
}
.add-button:hover {
  background-color: #CDCDCD;
  border: 1px solid #CDCDCD;
  border-radius: 100px;
}
.add-button:focus {
  background-color: #CDCDCD;
  border: 1px solid #CDCDCD;
  border-radius: 100px;
}
.action-button:hover {
  background-color: #CDCDCD;
  border: 1px solid #CDCDCD;
  border-radius: 100px;
}
.action-button:focus {
  background-color: #CDCDCD;
  border: 1px solid #CDCDCD;
  border-radius: 100px;
}
.button-wrapper {
  display: flex;
}
.modal-sheet {
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03); */
  border-radius: 10px;
  border: 1px solid #fff;
  width: var(--sheet-width);
  max-width: var(--max-width);
  height: var(--sheet-height);
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
}