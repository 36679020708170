.divider {
    height: 1px;
    background-color: #EAEAEA;
    margin: 0px;
    width: 100%;
}

.dashed-divider {
    height: 1px;
    border: none;
    border-top: var(--dash-size, 0.5px) dashed #D7D7D7;
}